/*
Author       : LitmusThemes
Template Name: Angles - Multipurpose One & Multi Page HTML5 template - ThemeForest
Version      : 1.1
*/

/*=============================================================
CSS SITEMAP >>>>

    01. GENERAL CSS
	02. PRELOADER CSS
    03. GENERAL CSS
	04. COMMON PAGES CSS
	05. MAIN NAVIGATION CSS
	06. HOMEPAGE MAIN SLIDER CSS
	07. WHO WE ARE SECTION CSS
	08. WHY CHOOSE US SECTION CSS
	09. WHAT WE DO SECTION CSS
	10. COUNTER SECTION CSS
	11. LATEST PROJECTS SECTION CSS
	12. TEAM SECTION CSS
	13. TESTIMONIALS SECTION CSS
	14. BLOG SECTION CSS
	15. OUR PARTNERS SECTION CSS
	16. CONTACT SECTION CSS
	17. FOOTER SECTION CSS
	18. TEAM PAGE CSS
	19. WORK PAGE CSS
	20. CONTACT PAGE CSS
	21. BLOG PAGE CSS
	22. BLOG DETAIL PAGE CSS
	23. ABOUT PAGE CSS
	24. PRICING CSS
	25. 404 PAGE
	26. LOGIN AND REGISTER PAGE
	27. ELEMENTS
	
	==========================
	Version      : 1.1 ( new pages )
	==========================
	28. PORTFOLIO PAGES
	29. COMING SOON
	30. MAINTENANCE

==================================================*/

#tagline {
  color: #adb3b3;
}

/*==============================================
01. GENERAL CSS
================================================*/

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}
body {
  font: 16px/1.625 "Poppins", Arial, Helvetica, sans-serif;
  color: #737373;
  font-weight: 400;
}
img {
  vertical-align: top;
  border: 0;
  max-width: 100%;
}
a,
input[type="button"],
input[type="submit"],
button,
table th,
table td {
  -moz-transition: background-color 550ms cubic-bezier(0.45, 1, 0.32, 1),
    color 550ms cubic-bezier(0.45, 1, 0.32, 1);
  -webkit-transition: background-color 550ms cubic-bezier(0.45, 1, 0.32, 1),
    color 550ms cubic-bezier(0.45, 1, 0.32, 1);
  -o-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  -ms-transition: background-color 550ms cubic-bezier(0.45, 1, 0.32, 1),
    color 550ms cubic-bezier(0.45, 1, 0.32, 1);
  transition: background-color 550ms cubic-bezier(0.45, 1, 0.32, 1),
    color 550ms cubic-bezier(0.45, 1, 0.32, 1);
}
.alltrans,
.btn,
.btn:before,
.owl-dot,
.owl-dot span,
.navtrigger,
.slider-content h1,
.slider-content h2,
.slider-content h3,
.slider-content h4,
.slider-content h5,
.slider-content p,
.slider-content .cta-row,
.main-slider .owl-nav > div,
.feature-col,
.service-col:after,
.feature-col:after,
#header,
.owl-nav > div,
.owl-nav > div:after,
.partners-slider img,
.team-overlay,
.team-overlay p,
.team-social-links,
.team-social-links a,
.work-overlay,
.blog-category:after,
#logo img,
.logo,
#header,
.side-menu-trigger,
.side-menu-trigger:after,
.navigation-menu > li > ul,
.sidenav-wrapper,
.navigation-menu > li > a,
.navigation-menu > li,
.contact-col i:after,
.search-trigger,
.pricing-box,
.team-header,
.search-wrapper,
.feature-col p,
.service-col p {
  -moz-transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1) !important;
  -webkit-transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1) !important;
  -o-transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1) !important;
  -ms-transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1) !important;
  transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1) !important;
}
a {
  color: #3a9ac8;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #3a9ac8;
  outline: none;
  text-decoration: underline;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  a:active {
    background-color: transparent;
  }
}
strong {
  font-weight: 700;
}
small,
.small {
  font-size: 85%;
}
sub,
sup {
  font-size: 80%;
}
p {
  margin: 0 0 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  margin: 0 0 15px;
  font-weight: 700;
  color: #242424;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}
h1,
h2 {
  font-size: 52px;
}
h3 {
  font-size: 30px;
  font-weight: 600;
}
h4 {
  font-size: 26px;
  font-weight: 600;
}
h5 {
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 600;
}
h6 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}
figure {
  margin: 0;
}
address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}
button:focus {
  outline: none;
}
.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  border-bottom-color: rgb(238, 238, 238);
}

/*==============================================
02. PRELOADER CSS
================================================*/
.loader {
  display: block;
  left: 50%;
  right: 50%;
  margin: auto;
  margin-top: 30px;

  border: 16px solid #222;
  border-radius: 50%;
  border-top: 16px solid #89c8db;
  width: 300px;
  height: 300px;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  background: #222;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  text-align: center;
}
.preloader h3 {
  margin-top: -240px;
  color: #242424;
  font-weight: 600;
  font-size: 26px;
  color: #fff;
  font-family: "Poppins";
}

/*==============================================
03. GENERAL CSS
================================================*/
#main {
  padding: 0;
  background: #fff;
}
@font-face {
  font-family: "PoppinsRegular";
  font-display: auto;
  src: url("/fonts/Poppins-Regular.eot");
  src: url("/fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Poppins-Regular.woff2") format("woff2"),
    url("/fonts/Poppins-Regular.woff") format("woff"),
    url("/fonts/Poppins-Regular.ttf") format("truetype"),
    url("/fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: 400;
  font-style: 400;
}
.no-margin {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.no-padding {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
hr {
  border-color: #e5e5e5;
}
.hr-lg {
  margin-top: 55px;
  margin-bottom: 55px;
}
blockquote {
  font-size: 16px;
  font-style: italic;
  border: 0;
  text-align: center;
  padding: 20px 0;
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
}
.blockquote-footer {
  padding-top: 35px;
  font-weight: 600;
  font-size: 16px;
}
.blockquote-footer.hr-primary:after {
  margin: 15px auto 20px;
  right: 0;
  width: 50px;
  height: 2px;
  left: 0;
  top: 0;
  bottom: auto;
}
blockquote .fa {
  color: #3a9ac8;
  font-size: 2em;
  display: block;
  margin: 0 0 5px;
}
.back-top {
  background: #3a9ac8;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  left: 25px;
  bottom: 25px;
  font-size: 30px;
  display: none;
  z-index: 10;
}
.back-top i {
  position: absolute;
  left: 16px;
  top: 8px;
  color: #fff;
}
.section-full {
  position: relative;
  overflow: hidden;
}
.bg-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  height: 100%;
  background-position: 50% 0;
}
.f-right {
  float: right;
}
.f-left {
  float: left;
}
.hr-primary {
  position: relative;
  padding-bottom: 8px;
}
.hr-primary:after {
  background: #3a9ac8 none repeat scroll 0 0;
  content: "";
  display: block;
  height: 1px;
  margin: 10px 0 0;
  width: 40px;
  bottom: -1px;
  position: absolute;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

/*==============================================
04. COMMON PAGES CSS
================================================*/
.container {
  max-width: 1170px;
  width: 100%;
}
#header {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  padding: 0px 0px 0px 10px;
}
.logo {
  float: left;
  height: 96px;
  display: table;
}
.logo span {
  display: table-cell;
  vertical-align: middle;
}
#logo {
  display: block;
  margin-top: 5px;
}
#logo picture {
  max-height: 96px !important;
  max-width: 350px !important;
}
#header.fixed .logo {
  max-height: 40px !important;
  max-width: 200px !important;
}
#header.fixed #logo source {
  max-height: 40px !important;
  max-width: 200px !important;
}
#header.fixed #logo img {
  max-height: 40px !important;
  max-width: 200px !important;
}
#header.fixed #tagline {
  font-size: 12px;
}
#header.fixed #tagline .fa {
  /*display:none;*/
}
.animated-row {
  overflow: hidden;
}
.section {
  padding: 60px 0 70px;
  overflow: hidden;
}
.section-title {
  position: relative;
  padding-bottom: 10px;
}
.section-title + * {
  margin-top: 40px;
}
.section-title:before,
.section-title:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: 6px;
  width: 35px;
  height: 2px;
  background: #81b541;
  top: 100%;
}
.section-title:after {
  left: auto;
  right: 50%;
  margin-right: 8px;
}
.section-title span:before,
.section-title span:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ea9355;
  left: 50%;
  top: 100%;
  margin-top: -2px;
  margin-left: 2px;
}
.section-title span:after {
  left: auto;
  right: 50%;
  margin-left: 0;
  margin-right: 2px;
}

.section-title-inverse {
  position: relative;
  padding-bottom: 10px;
}
.section-title-inverse span:before,
.section-title-inverse span:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ea9355;
  left: 50%;
  top: 100%;
  margin-top: -2px;
  margin-left: 2px;
}

.section-title span:after {
  left: auto;
  right: 50%;
  margin-left: 0;
  margin-right: 2px;
}

.section-title-inverse+* {
  margin-top: 40px;
}
.section-title-inverse:before,
.section-title-inverse:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: 6px;
  width: 35px;
  height: 2px;
  background: #81b541;
  top: 100%;
}

.section-title-inverse:after {
  left: auto;
  right: 50%;
  margin-right: 6px;
}

.section-title-inverse span:before,
.section-title-inverse span:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ea9355;
  left: 50%;
  top: 100%;
  margin-top: -2px;
  margin-left: 2px;
}

.section-title-inverse span:after {
  left: auto;
  right: 50%;
  margin-left: 0;
  margin-right: 2px;
}

/*==============================================
05. MAIN NAVIGATION CSS
================================================*/
.navbar {
  margin: 0;
  border: 0;
  border-radius: 0;
  min-height: 1px;
  position: static;
  display: block;
  padding: 0;
}
.navigation-row {
  float: right;
}
.navigation-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
}
.nav-box {
  float: right;
  padding: 0 5px 0 0;
}
.navigation-menu:after {
  content: "";
  display: table;
  clear: both;
}
.navigation-menu > li {
  float: left;
  margin: 0 0 0 1px;
}
.navigation-menu > li > a {
  color: #fff;
  padding: 0 18px;
  display: block;
  line-height: 122px;
}
.navigation-menu > li > span {
  color: #fff;
  padding: 0 18px;
  display: block;
  line-height: 122px;
}
.navigation-menu > li > a:hover,
.navigation-menu > li > a:focus,
.navigation-menu > li.active > a {
  background: #3a9ac8;
  color: #fff;
  text-decoration: none;
}
.sidenav-wrapper .nav > li > a:hover,
.sidenav-wrapper .nav > li > a:focus {
  background: none;
}
.sidenav-wrapper .nav > li.active > a {
  color: #3a9ac8;
}
.navigation-menu > li:hover {
  background: #3a9ac8;
}
.navigation-menu > li > ul {
  position: absolute;
  top: 115%;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.navigation-menu > li:last-child > ul {
  right: 0;
}
.navigation-menu > li > ul li a {
  padding: 11px 20px 10px;
  display: block;
  color: #737373;
  font-size: 14px;
  font-weight: 500;
}
.navigation-menu > li > ul li a:hover,
.navigation-menu > li > ul li a:focus {
  text-decoration: none;
  color: #3a9ac8;
}
.navigation-menu > li > ul li + li {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
.navigation-menu > li:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
#header.fixed {
  background: rgb(33 38 40 / 70%); /* #212628; */
  position: fixed;
}
#header.fixed .navigation-menu > li > a {
  line-height: 68px;
}

#navigation {
  float: left;
}
#sidenav {
  float: right;
  width: 50px;
  margin-left: 25px;
  min-height: 1px;
}
.side-menu-trigger,
.navbar-toggle {
  font-size: 25px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 35px;
  color: #fff;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  background: #3a9ac8;
  text-align: center;
  overflow: hidden;
}
.side-menu-trigger:hover,
.close-menu:hover,
.navbar-toggle:hover {
  background: #2b7396;
}
.side-menu-trigger i,
.navbar-toggle i {
  position: absolute;
  left: 0;
  right: 0;
  line-height: 46px;
  top: 0;
}
.side-menu-trigger:hover,
.side-menu-trigger:focus {
  color: #fff;
}
.navbar-toggle {
  margin: 0;
  padding: 0;
  right: 15px;
}
.sidenav-wrapper {
  position: fixed;
  background: #fff;
  top: 0;
  height: 100%;
  right: 0;
  width: 300px;
  right: -320px;
  padding: 90px 0 30px;
  -webkit-box-shadow: -5px 0 5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: -5px 0 5px rgba(0, 0, 0, 0.08);
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.08);
  overflow-y: auto;
}
.sidenav-wrapper.visible {
  right: 0;
}
.sidenav-wrapper .nav > li > a {
  padding: 10px 0;
  font-size: 18px;
  color: #737373;
  font-weight: 500;
}
.sidenav-wrapper .nav > li > a:hover {
  color: #3a9ac8;
}
.sidenav-wrapper .nav {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 30px 0;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sidenav-wrapper .nav ul {
  margin: 0;
  display: none;
  padding: 0 0 0 15px;
  list-style: none;
}
.sidenav-wrapper .nav ul li a {
  color: #737373;
  text-decoration: none;
  padding: 5px 0;
  display: block;
  font-size: 15px;
}
.sidenav-wrapper .nav ul li a:before {
  content: "\f105";
  font-family: "Font Awesome 6 Pro" !important;
  margin-right: 5px;
  font-size: 16px;
}
.sidenav-wrapper .nav ul li a:hover {
  color: #3a9ac8;
}
.close-menu {
  font-size: 54px;
  background: #3a9ac8;
  right: 20px;
  position: absolute;
  top: 22px;
  line-height: 50px;
  height: 46px;
  width: 46px;
  border-radius: 50%;
}
.close-menu:before,
.close-menu:after {
  content: "";
  position: absolute;
  top: 21px;
  height: 4px;
  width: 22px;
  border-radius: 2px;
  background: #fff;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close-menu:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sidenav-wrapper .social-links {
  padding: 20px 30px;
}
.sidenav-wrapper .social-links li:first-child {
  margin-left: 0;
}
.sidenav-wrapper .navtrigger {
  display: block;
}
.navtrigger {
}
.navtrigger.nav-open {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.search-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  padding: 40px;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  z-index: 999;
}
.search-wrapper.open {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.search-trigger {
  line-height: 86px;
  font-size: 20px;

  color: #fff;
  display: block;
}
.search-trigger:focus {
  color: #fff;
}
.search-box {
  max-width: 800px;
  width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.search-area {
  float: left;
}
.search-wrapper .submit-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: none;
  padding: 5px;
  font-size: 30px;
}
.search-wrapper input[type="text"],
.search-wrapper input[type="search"] {
  background: none;
  border-radius: 0;
  font-size: 56px;
  font-weight: 600;
  line-height: 1.4;
  padding-bottom: 15px;
  border: 0;
  border-bottom: 3px solid #fff;
  padding-right: 70px;
  padding-left: 0;
  height: auto;
  color: #fff;
}
#header.fixed .search-trigger {
  line-height: 68px;
}
.close-search {
  position: absolute;
  font-size: 50px;
  top: 25px;
  right: 40px;
  color: #fff;
}

/*==============================================
06. HOMEPAGE MAIN SLIDER CSS
================================================*/
.main-slider {
  height: 100vh;
}
.banner-section {
  background-color: #212628;
}
.banner-section.bg-dark:after {
  display: none;
}
.main-slider .item {
  position: relative;
  height: 100vh;
}
.slide-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  -webkit-background-position: 50% 0;
  background-position: 50% 0;
}
.slide-img img {
  width: 100%;
}
.slider-contentbox {
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: #fff;
  display: table;
  width: 100%;
  position: relative;
  z-index: 1;
  font-size: 18px;
}
.slider-content {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 90px;
}
.slider-content h1,
.slider-content h2,
.slider-content h3 {
  color: inherit;
  font-size: 80px;
}
.slider-content h1,
.slider-content h2,
.slider-content h3 {
  line-height: 1.2;
  margin-bottom: 30px;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
  opacity: 0;
  overflow: hidden;
}
.slider-content h4,
.slider-content h5 {
  line-height: 1.2;
  margin-bottom: 50px;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
  opacity: 0;
  overflow: hidden;
}

.slider-content p {
  margin-bottom: 45px;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
  opacity: 0;
}
.slider-content .cta-row {
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
  opacity: 0;
}
.main-slider .owl-item.active .slider-content h1,
.main-slider .owl-item.active .slider-content h2,
.main-slider .owl-item.active .slider-content h3,
.main-slider .owl-item.active .slider-content h5,
.main-slider .owl-item.active .slider-content p,
.main-slider .owl-item.active .slider-content .cta-row {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.owl-dots {
  text-align: center;
  font-size: 0;
  margin: 30px 0;
}
.owl-dot {
  display: inline-block;
  margin: 0 4px;
  border: 2px solid transparent;
  padding: 2px;
  border-radius: 50%;
}
.owl-dot span {
  background: #242424;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: block;
}
.owl-dot.active,
.owl-dot:hover {
  border-color: #3a9ac8;
}
.owl-dot.active span,
.owl-dot:hover span {
  background: #3a9ac8;
}
.owl-nav > div {
  position: absolute;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
}
.owl-nav > div.owl-next {
  left: auto;
  right: 25px;
}
.main-slider .owl-nav > div {
  color: #fff;
  border-color: #fff;
  color: #fff;
}
.owl-nav > div:hover {
  opacity: 1;
}
.owl-prev,
.owl-next {
  border: 2px solid #656565;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  color: #656565;
  opacity: 0.4;
}
.owl-prev:after,
.owl-next:after {
  font-family: "Font Awesome 6 Pro" !important;
  content: "\f104";
  font-size: 40px;
  display: block;
  line-height: 48px;
  margin: 0 0 0 -1px;
}
.owl-next:after {
  content: "\f105";
  margin: 0 0 0 4px;
}

.bg-light .slider-contentbox {
  background: none;
}
.scroll-down {
  position: absolute;
  text-align: center;
  width: 22px;
  height: 22px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 30px;
  z-index: 2;
  -webkit-animation: up-down 0.5s ease-in-out infinite alternate;
  animation: up-down 0.5s ease-in-out infinite alternate;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
@-webkit-keyframes up-down {
  0% {
    -webkit-transform: translateY(0) rotate(45deg);
  }
  100% {
    -webkit-transform: translateY(5px) rotate(45deg);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(0) rotate(45deg);
  }
  100% {
    transform: translateY(5px) rotate(45deg);
  }
}

/*==============================================
07. WHO WE ARE SECTION CSS
================================================*/
.title-col {
  /* margin-bottom: 40px; */
}
.section-title + h4 {
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 0.2em;
  font-weight: 600;
}
.feature-col {
  font-size: 14px;
  padding: 15px 15px 20px;
  position: relative;
  z-index: 1;
}
.feature-col img {
  margin-bottom: 30px;
  height: 100px;
}
.feature-col p {
  margin: 0;
}
.feature-col:hover *,
.service-col:hover * {
  color: #fff;
}
.feature-col i {
  font-size: 44px;
  color: #3a9ac8;
  margin-bottom: 20px;
}
.text-white {
  color: #fff;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6 {
  color: #fff;
}

/*==============================================
08. WHY CHOOSE US SECTION CSS
================================================*/
.bg-primary {
  
  background: #47acc3 !important;
  /* background: #3a9ac8 !important; */

}
.why-choose-contentbox {
  padding: 50px;
  color: #fff;
}
.why-choose-contentbox h2 {
  color: #fff;
  margin-bottom: 55px;
}
.why-choose-col {
  position: relative;
  font-size: 0;
  margin-bottom:20px;
}
.why-choose-col .why-choose-icons {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #fff;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
}
.why-choose-col > i {
  font-size: 50px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  line-height: 92px;
  display: inline-block;
  vertical-align: middle;
}
.why-choose-content {
  overflow: hidden;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 30px;
  width: -moz-calc(100% - 90px);
  width: -webkit-calc(100% - 90px);
  width: calc(100% - 90px);
}

.why-choose-content h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px;
}
.why-choose-content-inverted {
  overflow: hidden;
  font-size: 15px;
  display: inline-block;
  vertical-align: top;
  padding-left: 30px;
  width: -moz-calc(100% - 90px);
  width: -webkit-calc(100% - 90px);
  width: calc(100% - 90px);
}

.why-choose-content-inverted h3 {
  color: #1c3133;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px;
}
.why-choose-col + .why-choose-col {
  /*margin-top: 25px;*/
}
.why-choose-content p {
  margin: 0;
}
.why-choose-content-inverted p {
  margin: 0;
}



.features-list-contentbox {
  padding: 10px;
  color: #fff;
}

.features-list-contentbox h2 {
  color: #fff;
  margin-bottom: 55px;
}

.features-list-col {
  position: relative;
  font-size: 0;
}

.features-list-col>i {
  font-size: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(36, 36, 36, 0.28);
  text-align: center;
  line-height: 63px;
  display: inline-block;
  vertical-align: middle;
}

.features-list-content {
  overflow: hidden;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 30px;
  width: -moz-calc(100% - 90px);
  width: -webkit-calc(100% - 90px);
  width: calc(100% - 90px);
}

.features-list-content h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 5px;
}

.features-list-content-inverted {
  overflow: hidden;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 30px;
  width: -moz-calc(100% - 90px);
  width: -webkit-calc(100% - 90px);
  width: calc(100% - 90px);
}

.features-list-content-inverted h3 {
  color: #1c3133;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px;
}

.features-list-col+.features-list-col {
  margin-top: 25px;
}

.features-list-content p {
  margin: 0;
}

.features-list-content-inverted p {
  margin: 0;
}

.video-col {
  height: 100%;
}
.video-col .video-popup {
  height: 100%;
  position: relative;
  display: block;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-background-position: 50% 0;
  background-position: 50% 0;
}
.video-col .video-popup i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 110px;
  color: #000;
  opacity: 0.56;
}

/*==============================================
09. WHAT WE DO SECTION CSS
================================================*/
.service-col {
  border: 1px solid #e5e5e5;
  font-size: 14px;
  padding: 40px 35px 45px;
  position: relative;
  z-index: 1;
  margin-top: 30px;
}
.service-col:after,
.feature-col:after {
  content: "";
  background: #3a9ac8;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}
.service-col h3 {
  font-size: 22px;
  letter-spacing: 0.02em;
  margin-bottom: 15px;
}
.service-col p {
  margin: 0;
}
.service-col i {
  font-size: 44px;
  color: #3a9ac8;
  margin-bottom: 25px;
}
.service-col:hover:after,
.feature-col:hover:after {
  height: 100%;
}
.services-list .row {
  margin-top: -30px;
}
.services-list .column:nth-of-type(4) {
  clear: left;
}
.bg-grey {
  background: #f4f4f4;
}

/*==============================================
10. COUNTER SECTION CSS
================================================*/
.bg-dark {
  background: #212628;
  color: #fff;
  position: relative;
  z-index: 1;
}
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6 {
  color: #fff;
}
.bg-dark:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  top: 0;
  right: 0;
  background: #212628;
  opacity: 0.9;
  height: 100%;
  z-index: -1;
}
.bg-dark .owl-dot span {
  background: #929596;
  background: rgba(255, 255, 255, 0.5);
}
.bg-dark .owl-dot.active,
.bg-dark .owl-dot:hover {
  border-color: #fff;
}
.bg-dark .owl-dot.active span,
.bg-dark .owl-dot:hover span {
  background: #fff;
}
.counter-box {
  text-align: center;
  font-size: 18px;
  color: #bcbcbd;
}
.count-number {
  font-weight: 600;
  display: block;
  font-size: 70px;
  line-height: 1.2;
  color: #fff;
}

.webp #counters-section {
  background: url(/images/stock/licensed/optimized/1125567959.webp) no-repeat
    50% 0;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}
.no-webp #counters-section {
  background: url(/images/stock/licensed/optimized/1125567959.jpg) no-repeat
    50% 0;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

/*==============================================
11. LATEST PROJECTS SECTION CSS
================================================*/
#work-section {
  padding-bottom: 0;
}
.work-row {
  position: relative;
}
.work-row .btn-projects {
  position: absolute;
  top: -24px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.project-box {
  position: relative;
  overflow: hidden;
}
.project-box:hover .work-overlay {
  opacity: 1;
}
.work-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: #000;
  background: rgba(0, 0, 0, 0.88);
  color: #fff;
  color: #bbb;
  letter-spacing: 0.02em;
  font-size: 14px;
  z-index: 1;
  opacity: 0;
}
.project-details {
  position: absolute;
  border: 4px solid rgba(255, 255, 255, 0.2);
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  background: rgba(0, 0, 0, 0.2);
}
.project-content {
  position: absolute;
  padding: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}
.project-details h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 5px;
}
.project-catagory {
  color: #3a9ac8;
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}
.project-details p {
  margin-bottom: 20px;
}
.zoom-icon {
  width: 62px;
  height: 62px;
  background: #fff;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 32px;
  line-height: 62px;
}

.mfp-container .mfp-close {
  background: #3a9ac8;
  color: #fff;
  line-height: 42px;
  height: 50px;
  width: 50px;
  font-size: 34px;
  right: 0;
  opacity: 1;
  border-radius: 0;
  text-align: center;
  font-weight: 400;
  top: 0;
  padding: 0;
  position: fixed;
}
.mfp-bottom-bar {
  font-size: 20px;
  font-weight: 600;
  height: auto;
  padding: 0 65px;
  min-height: 50px;
  top: 0;
  margin: 0;
  background: #212628;
  position: fixed;
  left: 0;
  right: 0;
}
.mfp-img-mobile .mfp-bottom-bar {
  bottom: auto;
  top: 0;
  background: #212628;
}
.mfp-title {
  text-align: center;
  padding: 16px 0;
}
img.mfp-img,
.mfp-img-mobile img.mfp-img {
  padding: 50px 0;
}
.mfp-figure:after {
  top: 50px;
  bottom: 50px;
}
.mfp-img-mobile img.mfp-img {
  padding: 60px 0 25px;
}
.mfp-img-mobile .mfp-figure:after {
  top: 60px;
  bottom: 25px;
}
.mfp-counter {
  display: none;
}
.mfp-container .mfp-arrow:before {
  display: none;
}

/*==============================================
12. TEAM SECTION CSS
================================================*/
.team-box {
  position: relative;
  overflow: hidden;
  padding: 10px;
}
.team-box picture {
  width: 100%;
}
.team-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: #000;
  height: 87px;
  background: rgba(0, 0, 0, 0.88);
  letter-spacing: 0.02em;
  height: 100%;
}
.team-header {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  padding: 20px 30px 15px;
  background: rgba(0, 0, 0, 0.88);
}
.team-overlay p {
  opacity: 0;
  margin: 30px 0;
  font-size: 13px;
  -webkit-transform: translateY(60px);
  transform: translateY(60px);
  color: #bbb;
}
.team-box:hover .team-overlay {
  top: 0;
  height: 100%;
}
.team-box:hover .team-header {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  background: none;
  padding: 0;
}
.team-box:hover .team-overlay p {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.team-box:hover .team-details h4 {
  margin-bottom: 3px;
}
.team-box:hover .team-social-links {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.team-box:hover .team-details {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 30px;
  right: 30px;
  margin: 0 auto;
}
.team-header h4 {
  font-size: 20px;
  margin: 0;
}
.team-social-links {
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-transform: translateY(60px);
  transform: translateY(60px);
}
.team-social-links li {
  display: inline-block;
  margin: 0 2px;
}
.team-social-links li a {
  font-size: 16px;
  display: block;
  color: #fff;
  height: 36px;
  width: 36px;
  border: 2px solid #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 34px;
  opacity: 0.6;
}
.team-social-links li a:hover {
  opacity: 1;
}
.team-slider .owl-nav > div.owl-prev {
  left: -75px;
}
.team-slider .owl-nav > div.owl-next {
  left: auto;
  right: -75px;
}
.owl-carousel .owl-nav > div.disabled {
  opacity: 0.25;
  cursor: default;
}
.team-row .row {
  margin-top: -30px;
}
.team-row .row .column {
  margin-top: 30px;
}

/*==============================================
13. TESTIMONIALS SECTION CSS
================================================*/
.webp #integration-section {
  background: url(/images/stock/licensed/optimized/168272219.webp) no-repeat
    50% 0;

  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}
.no-webp #integration-section {
  background: url(/images/stock/licensed/optimized/168272219.jpg) no-repeat
    50% 0;

  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}
.quote-icon {
  font-size: 40px;
  display: block;
  opacity: 0.22;
  line-height: 1.2;
  margin-bottom: 10px;
}
.testimonials-row {
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
}
.testimonial-box {
  letter-spacing: 0.02em;
}
.testimonial-box p {
  color: #d7d7d8;
  letter-spacing: 0;
  margin-bottom: 30px;
}
.testimonial-box h4 {
  font-size: 20px;
  color: #3a9ac8;
  margin-bottom: 5px;
}
.testimonials-slider .owl-dots {
  margin-bottom: 0;
}


/*==============================================
14. BLOG SECTION CSS
================================================*/
.blog-box {
	font-size: 14px;
	text-align:left;
}
.blog-img-box {
	position: relative;
}
.blog-img-box img {
	width: 100%;
}
.blog-category {
	position: absolute;
	left: 0;
	top: 20px;
	background: #fff;
	background: rgba(255,255,255,0.85);
	color: #242424;
	padding: 12px 25px;
	line-height: 1.2;
	font-size: 18px;
	font-weight: 500;
	z-index: 1;
}
.blog-category:after {
	content: '';
	left: 0;
	width: 0;
	background: #3a9ac8;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
}
.blog-box:hover .blog-category {
	color: #fff;
}
.blog-box:hover .blog-category:after {
	width: 100%;
}
.blog-info {
	border: 1px solid #e5e5e5;
	border-top: 0;
}
.blog-details {
	padding: 20px;
}
.blog-details p {
	margin: 0;
}
.blog-details p + p {
	margin:15px 0;	
}
.posted-date {
	display: block;
	color: #3a9ac8;
	font-weight: 500;
	margin-bottom: 15px;
	padding-bottom:5px;
}
.posted-date:after {
	width: 34px;
}
.post-title {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 10px;
}
.post-title a {
	display: block;
	color: #242424;
}
.post-title a:hover, 
.post-title a:focus {
	text-decoration: none;
	color: #3a9ac8;
}
.blog-bottom-row {
	border-top: 1px solid #e5e5e5;
	padding: 13px 20px;
}
.blog-bottom-row:after {
	content: '';
	display: table;
	clear: both;
}
.posted-by {
	float: left;
	margin-right: 20px;
	font-weight: 500;
	color: #959595;
}
.posted-by i, 
.comments i {
	font-size: 1.4em;
	margin-right: 4px;
}
.posted-by a {
	color: inherit;
}
.posted-by a:hover, 
.posted-by a:focus {
	color: #3a9ac8;
	text-decoration: none;
}
.comments {
	float: right;
	font-weight: 500;
	color: #959595;
}
.blog-posts .btn {
	margin-top: 35px;
}

/*==============================================
15. OUR PARTNERS SECTION CSS
================================================*/
.partners-slider img {
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.35;
  filter: alpha(opacity=50);
  cursor: default;
}
.partners-slider img:hover {
  -webkit-filter: none;
  -ms-filter: none;
  -moz-filter: none;
  filter: none;
  opacity: 1;
  filter: alpha(opacity=100);
}
#partners-section {
  padding: 35px 0;
}
#partners-section h3 {
  font-weight: 600;
  margin: 0;
}

/*==============================================
16. CONTACT SECTION CSS
================================================*/
#contact-section > .row {
  margin: 0;
}
#contact-section > .row > .column {
  padding: 0;
}
.contact-row .contact-box {
  background: url(/images/bg/form-bg.jpg) no-repeat 50% 0 fixed;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
  z-index: 1;
  min-height: 600px;
}

.contact-row .contact-box:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  background: #212628;
  opacity: 0.9;
  z-index: -1;
}
.contact-row .contact-box h2 {
  color: #fff;
}
.contact-box {
  padding: 65px 75px 75px;
}
.contact-box h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.contact-map {
  width: 100%;
  height: 600px;
}
.bg-grey .form-control {
  background: #fff;
}
.form-group {
  margin-bottom: 25px;
}

/*==============================================
17. FOOTER SECTION CSS
================================================*/
#footer {
  background: #242424;
  color: #aaa;
  font-weight: 300;
  font-size: 15px;
  z-index: 400;
}
.quick-contact {
  padding: 50px 0;
}
.quick-contact h3 {
  color: #fff;
  margin: 0;
}

.footer-cols {
  padding: 45px 0 35px;
}
.footer-cols h4 {
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  letter-spacing: 0.01em;
}
.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-list li {
  line-height: 1.8;
  white-space: nowrap !important;
}
.nav-list li a {
  text-decoration: none;
}
.nav-list li a:before {
  /*
  content: "\f105";
  font-family: "Font Awesome 6 Pro" !important;
  */
  margin-right: 10px;
  font-size: 1.1em;
}
.footer-cols .nav-list li a {
  color: #aaa;
}
.footer-cols .nav-list li a:hover {
  color: #fff;
}
.getintouch {
  position: relative;
  padding-left: 42px;
}
.getintouch i {
  font-size: 1.8em;
  position: absolute;
  left: 0;
  top: -2px;
  opacity: 0.85;
}
.email-col.getintouch i {
  font-size: 1.5em;
}
.getintouch address {
  margin: 0;
}
.getintouch ~ .getintouch {
  margin-top: 20px;
}
.getintouch a {
  color: #aaa;
}
.getintouch a:hover {
  color: #fff;
  text-decoration: none;
}
.footer-newsletter {
  position: relative;
  margin-bottom: 30px;
}
.footer-newsletter .form-control {
  border: 0;
  color: #aaa;
  background: #fff;
  background: rgba(255, 255, 255, 0.08);
  font-weight: 300;
  padding-left: 50px;
  padding-right: 135px;
}
.footer-newsletter i {
  position: absolute;
  top: 13px;
  left: 15px;
  font-size: 24px;
}
.newsletter-subscribe .btn {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: none;
  font-size: 15px;
  height: 50px;
  padding-left: 18px;
  padding-right: 18px;
}
.social-links:after {
  content: "";
  display: table;
  clear: both;
}
.social-links {
  margin: 0;
  padding: 0;
  list-style: none;
}
.social-links li {
  float: left;
  margin-left: 10px;
}
.social-links li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: block;
  line-height: 44px;
  background: #26a6d1;
  color: #fff;
  font-size: 22px;
}
.social-links li.fb a {
  background: #3b5998;
}
.social-links li.yt a {
  background: #c4302b;
}
.social-links li.in a {
  background: #0e76a8;
}
.social-links li.ig a {
  background: #fb3958;
}
.social-block span {
  float: left;
  margin: 7px 10px 0 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.copyright {
  font-size: 14px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px 0;
  text-align: center;
}

/*==============================================
18. TEAM PAGE CSS
================================================*/
.inner-content {
  position: relative;
  z-index: 1;
  padding: 200px 30px 100px;
  text-align: center;
}
.inner-banner.bg-dark:after {
  display: block;
  z-index: 0;
  opacity: 0.85;
}
/*==============================================
19. WORK PAGE CSS
================================================*/

.work-row .row {
  margin-top: -30px;
}
.work-row .column {
  margin-top: 30px;
}

/*==============================================
20. CONTACT PAGE CSS
================================================*/
.contact-col {
  text-align: center;
  font-size: 20px;
}
.contact-col * {
  color: #737373;
}
.contact-col i {
  font-size: 40px;
  color: #3a9ac8;
  margin: 0 auto 20px;
  display: block;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  line-height: 90px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.contact-col i:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: #3a9ac8;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.contact-col:hover i:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.contact-col:hover i {
  color: #fff;
}
.contact-col address {
  font-size: 18px;
  margin: 0;
}

.contact-inforow .contact-box {
  padding: 0;
  text-align: center;
}

/*==============================================
21. BLOG PAGE CSS
================================================*/
.blog-section .blog-box {
  margin-bottom: 30px;
}
.pagination-section {
  text-align: center;
}

/* ------ SIDEBAR CSS ------ */
.widget-single {
  background: #f4f4f4;
  padding: 20px;
}
.widget-single ~ .widget-single {
  margin-top: 25px;
}
.widget-single h4 {
  font-size: 20px;
  padding-bottom: 8px;
  margin-bottom: 15px;
  position: relative;
  font-weight: 600;
}
.widget-single ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget-single li:after {
  clear: both;
  display: table;
  content: "";
}
.list li {
  border-top: 1px dashed #dbdbdb;
  padding: 8px 0;
  font-size: 15px;
}
.list li:first-child,
.widget-recent-blog li:first-child {
  border: 0;
}
.list li a {
  color: #737373;
  padding-left: 11px;
  text-decoration: none;
  display: block;
  word-wrap: break-word;
  position: relative;
}
.widget-single li a:hover {
  color: #3a9ac8;
}
.list li a:before {
  content: "\f105";
  font-family: "Font Awesome 6 Pro" !important;
  position: absolute;
  left: 0;
  top: -1px;
}

.search-section {
  position: relative;
}
.search-section input[type="text"] {
  background: #fff;
  padding-right: 60px;
  color: #737373;
}
.search-section .btn-search {
  cursor: pointer;
  font-size: 0;
  text-align: center;
  top: 1px;
  bottom: 1px;
  padding: 0;
  position: absolute;
  right: 0;
  width: 50px;
  line-height: 44px;
}
.btn-search i {
  font-size: 20px;
  display: block;
  margin-left: 2px;
}

.widget-categories li {
  padding-right: 35px;
  position: relative;
}
.widget-categories span {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  height: 26px;
  line-height: 28px;
  text-align: center;
  width: 26px;
  position: absolute;
  top: 6px;
  right: 0;
}

.widget-recent-blog li {
  padding: 12px 0;
  position: relative;
  border-top: 1px dashed #dbdbdb;
}
.widget-recent-blog li:first-child {
  border: 0;
}
.widget-recent-blog figure {
  float: left;
  margin-right: 15px;
  max-width: 90px;
  width: 100%;
}
.widget-recent-blog img {
  max-width: 100%;
}
.post-summary {
  overflow: hidden;
}
.post-summary h5 {
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 2px;
}
.post-summary a {
  color: #242424;
  display: block;
  text-decoration: none;
}
.post-summary a:hover {
  color: #3a9ac8;
}
.post-summary .posted-date {
  font-size: 12px;
  margin: 0;
  color: #737373;
  font-weight: 400;
}
.post-summary .posted-date:after {
  display: none;
}

.taglist:after {
  content: "";
  display: table;
  clear: both;
}
.taglist li {
  float: left;
  margin: 8px 8px 0 0;
}
.taglist li a {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 13px;
  background: #fff;
  display: block;
  color: #737373;
}
.taglist li a:hover {
  background: #3a9ac8;
  color: #fff;
  text-decoration: none;
  border-color: #3a9ac8;
}

/*==============================================
22. BLOG DETAIL PAGE CSS
================================================*/
h1.post-title {
  font-size: 30px;
  margin: 0 0 20px;
}
.tags-share:after,
.author-inforow:after {
  content: "";
  display: table;
  clear: both;
}
.tags-share {
  border-bottom: 1px solid #e5e5e5;
}
.tags-list {
  float: left;
}
.tags-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.tags-list ul li {
  float: left;
  margin-right: 15px;
}
.tags-list ul li a {
  color: #737373;
  font-size: 16px;
  text-decoration: none;
}
.tags-list ul li a:hover {
  color: #3a9ac8;
}
.tags-list h6 {
  float: left;
  margin-right: 15px;
}
.social-share-links.social-links li {
  margin-left: 6px;
}
.social-share-links.social-links li a {
  width: 34px;
  height: 34px;
  font-size: 18px;
  line-height: 34px;
}
.author-inforow {
  background: #f4f4f4;
}
.author-inforow img {
  float: left;
}
.author-infobox {
  overflow: hidden;
  font-size: 14px;
  padding: 20px 20px 0;
}

.comments h3 {
  font-size: 24px;
}
.comments-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.comments-list .avatar img {
  float: left;
  margin-right: 25px;
  border-radius: 50%;
}
.comment-details {
  overflow: hidden;
  font-size: 14px;
}
.comment-by {
  position: relative;
  margin-bottom: 10px;
  padding-right: 100px;
}
.comment-by .date {
  display: block;
  font-weight: 400;
  color: #737373;
  font-size: 14px;
}
.comment-by .reply {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #3a9ac8;
  padding: 4px 10px 3px;
  color: #3a9ac8;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
}
.comment-by .reply:hover {
  background: #3a9ac8;
  color: #fff;
}
.comment-by {
  color: #242424;
  font-weight: 600;
  font-size: 16px;
}
.comments-list ul {
  list-style: none;
  margin: 20px 0 0;
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  padding: 20px 0 5px 70px;
}
.comments-list li + li {
  margin-top: 20px;
}
.comments-list ul li + li {
  margin-top: 15px;
}

/*==============================================
23. ABOUT PAGE CSS
================================================*/
.sub-title {
  display: block;
  font-size: 0.6em;
  text-transform: uppercase;
  font-weight: 500;
  color: #3a9ac8;
  margin-bottom: 5px;
  letter-spacing: 0.02em;
}
.skill-bar {
  height: 8px;
  position: relative;
  background-color: #f4f4f4;
  border-radius: 50px;
}
.skill-bar + .skill-bar {
  margin: 65px 0 0 0;
}
.skill-bar h5 {
  display: block;
  float: left;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  top: -35px;
  margin: 0;
}
.progress-bar-value {
  display: block;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: #3a9ac8;
  border-radius: 50px;
}
.progress-value {
  position: absolute;
  right: 0;
  top: -30px;
  font-size: 14px;
}

/*==============================================
24. PRICING CSS
================================================*/

#pricing-section {
  border-top: 1px solid #e5e5e5;
}
.pricing-box {
  text-align: center;
  background: #f4f4f4;
  cursor: pointer;
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
  border: 1px solid #e5e5e5;
}
.pricing-box.popular-plan {
  background: #3a9ac8;
}
.pricing-box.popular-plan,
.pricing-box.popular-plan .plan-price,
.pricing-box.popular-plan h3 {
  color: #fff !important;
}
.popular-plan.pricing-box .plan-features-list ul li,
.pricing-box.popular-plan .plan-features-list ul {
  border-color: rgba(255, 255, 255, 0.25);
}
.pricing-box.popular-plan .btn.btn-outline {
  border-color: #fff;
  color: #fff;
}
.pricing-box:hover .btn.btn-outline {
  color: #fff;
}
.pricing-box:hover .btn.btn-outline:before {
  height: 100%;
}
.pricing-box.popular-plan .btn.btn-outline:before {
  background: #fff;
}
.pricing-box.popular-plan:hover .btn.btn-outline {
  color: #3a9ac8;
}
.pricing-header {
  padding: 30px 20px 20px;
  position: relative;
}
.pricing-header h3 {
  letter-spacing: 0.02em;
  font-size: 24px;
}
.plan-price {
  font-size: 66px;
  font-weight: 700;
  color: #fff !important;
  line-height: 1.15;
  padding-top: 10px;
}
.plan-price sub {
  font-size: 18px;
  font-weight: 500;
  bottom: 5px;
}
.plan-features-list {
}
.plan-features-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px dashed #d7d7d7;
}
.plan-features-list ul li {
  padding: 12px 15px;
  border-top: 1px dashed #d7d7d7;
}
.pricing-footer {
  padding: 25px;
}
.pricing-footer .btn {
  min-width: 180px;
}
.popular-tag {
  color: #3a9ac8;
  background: #fff;
  position: absolute;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  padding: 7px 25px 7px 15px;
  height: 36px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  top: 11px;
  right: 0;
  font-weight: 500;
  font-size: 14px;
}
.popular-tag:after {
  content: "";
  position: absolute;
  right: -1px;
  top: 0;
  border-right: 15px solid #3a9ac8;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  width: 0;
  height: 0;
}

/*==============================================
25. 404 PAGE CSS
================================================*/
.error-404 {
  height: 100vh;
  color: #fff;
  position: relative;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-position: 50% 0;
  background-position: 50% 0;
  padding: 120px 0 30px;
}
.error-content {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 20px;
  font-size: 18px;
}
.error-content h1 {
  color: #fff;
  font-size: 200px;
  line-height: 1;
}
.error-content h3 {
  color: #fff;
}
.error-content p {
  margin-bottom: 30px;
}

/*==============================================
26. LOGIN AND REGISTER PAGE
================================================*/
.forgot-link {
  float: right;
}
.account-box button {
  width: 100%;
}
.account-box .nav-tabs {
  font-size: 18px;
}

/*==============================================
27. ELEMENTS CSS
================================================*/
.page-header {
  padding-bottom: 0;
  text-align: left;
  border-color: #e5e5e5;
}
.page-header h3 {
  margin-bottom: 10px;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background: #3a9ac8;
  border-color: #3a9ac8;
}
.dropdown-menu {
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #3a9ac8;
  border-color: #3a9ac8;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background: #3a9ac8;
  color: #fff;
  text-decoration: none;
  outline: 0;
}
.dropdown-menu > li > a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
  box-shadow: none;
}

.label,
.badge {
  font-weight: 600;
  padding: 0.3em 0.6em;
}
.label-primary,
.badge-primary {
  background: #3a9ac8;
}
.badge {
  padding: 0.45em 0.6em;
  font-size: 75%;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.page-header {
  margin-bottom: 30px;
}
.page-header h3 {
  font-size: 26px;
}
.custom-list {
  margin: 0;
  padding: 5px 0 0;
  list-style: none;
}
.custom-list li {
  margin: 0 0 5px;
}
.custom-list li i {
  font-size: 1.1em;
  margin-right: 5px;
}
.text-success {
  color: #5cb85c;
}
.text-primary {
  color: #3a9ac8 !important;
}
.text-warning {
  color: #f0ad4e;
}
.text-info {
  color: #5bc0de;
}
.text-danger {
  color: #d43f3a;
}
.container .jumbotron,
.container-fluid .jumbotron {
  padding-right: 60px;
  padding-left: 60px;
}

/* Alertbox CSS
========================================================================================*/
.alert {
  padding: 16px 16px 16px 60px;
  margin: 20px 0;
  color: #242424;
  position: relative;
}
.alert-notification {
  background-color: #fff0f4;
  border: solid 1px #db524c;
  color: #db524c;
}
.alert-info {
  background-color: #e8f6ff;
  border: solid 1px #0082d5;
  color: #0082d5;
}
.alert-warning {
  background-color: #fef4ec;
  border: solid 1px #ff9948;
  color: #ff9948;
}
.alert-success {
  background-color: #edfff6;
  border: solid 1px #04be5b;
  color: #04be5b;
}
.alert i {
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 1.8em;
}

/* Tabs
==========================================================*/
.nav-tabs {
  margin-bottom: 15px;
}
.nav-tabs > li > a {
  font-weight: 500;
  color: #737373;
  padding: 12px 20px;
  border: 0;
}
.nav-tabs > li > a:hover {
  border-color: transparent;
}
.nav-tabs > li > a.active,
.nav-tabs > li > a.active:hover,
.nav-tabs > li > a.active:focus {
  color: #3a9ac8;
  border: 0;
  border-bottom: 2px solid #3a9ac8;
}
.custom-tab.nav-tabs {
  border: 0;
}
.custom-tab.nav-tabs > li > a,
.custom-tab.nav-tabs > li > a.active,
.custom-tab.nav-tabs > li > a.active:hover,
.custom-tab.nav-tabs > li > a.active:focus {
  border: 0;
}
.custom-tab.nav-tabs > li > a {
  background: #e5e5e5;
  border-radius: 5px;
  margin-right: 8px;
}
.custom-tab.nav-tabs > li > a.active,
.custom-tab.nav-tabs > li > a.active:hover,
.custom-tab.nav-tabs > li > a.active:focus {
  background: #3a9ac8;
  color: #fff;
}
.tabs-left.nav-tabs > li > a.active,
.tabs-left.nav-tabs > li > a.active:hover,
.tabs-left.nav-tabs > li > a.active:focus {
  border: 0;
  border-right: 1px solid #3a9ac8;
}
.tabs-left.nav-tabs > li > a {
  display: block;
  margin: 0;
  border-radius: 0;
  border-right: 1px solid #ddd;
}
.tabs-left.nav-tabs > li {
  float: none;
}
.tabs-left.nav-tabs {
  border: 0;
  display: block;
}
.nav-tabs.verticle-tabs-default > li > a {
  background: #e5e5e5;
  border: 0;
}
.nav-tabs.verticle-tabs-default > li > a.active,
.nav-tabs.verticle-tabs-default > li > a.active:hover,
.nav-tabs.verticle-tabs-default > li > a.active:focus {
  background: #3a9ac8;
  color: #fff;
}

/* Accordians
==========================================================*/
.panel-title {
  font-size: 18px;
}
.accordion-box {
  border: 1px solid #ddd;
}
.accordion-row {
  margin-bottom: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #ddd;
  background: none;
}
.accordion-row:last-child {
  border-width: 0;
}
.accordion-row .panel-title {
  font-weight: 500;
  margin: 0;
}
.accordion-row .panel-title a {
  text-decoration: none;
  color: #737373;
  display: block;
  position: relative;
  padding: 20px 50px 20px 20px;
}
.accordion-row .panel-title a i {
  position: absolute;
  top: 17px;
  right: 17px;
  font-size: 1.6em;
}
.accordion-row .panel-title a[aria-expanded="true"] {
  background: #3a9ac8;
  color: #fff;
}
.accordion-row .panel-title a[aria-expanded="true"] i {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.accordion-row .panel-title a[aria-expanded="true"] i.fa-plus-circle {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.accordion-row .accordion-content {
  padding: 20px;
  font-size: 15px;
}
.accordion-row .accordion-content p:last-child {
  margin: 0;
}
.accordion-box.accordion-custom {
  border: 0;
}
.accordion-custom .accordion-row:last-child {
  border-width: 1px;
}

/* Spinners
==========================================================*/
.spinners i {
  margin-right: 20px;
  margin-bottom: 10px;
  color: #242424;
}
.spinner-md {
  font-size: 36px;
}
.spinner-lg {
  font-size: 72px;
}
.fa-spin-custom {
  -webkit-animation: spin 2000ms infinite linear;
  animation: spin 2000ms infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* Modals
==========================================================*/
.modal-header {
  padding: 15px 20px;
}
.modal-body {
  padding: 20px;
}
.modal-body p:last-child {
  margin: 0;
}
.modal-header h4 {
  font-size: 24px;
  padding-right: 30px;
}
.modal-header .close {
  margin-top: 2px;
  padding: 0;
  opacity: 1;
  color: #666;
  font-size: 30px;
  margin: 0 0 0 auto;
}

/* Pagination
==========================================================*/
.pagination {
  margin: 0;
  font-size: 0;
  display: block;
}
.pagination > li {
  display: inline-block;
  margin: 0 4px;
  text-align: center;
  font-size: 16px;
}
.pagination > li > a,
.pagination > li > span {
  display: block;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  line-height: 46px;
  border: 1px solid #d1d1d1;
  color: #737373;
  padding: 0;
  margin: 0;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 50%;
}
.pagination > li > a:hover,
.pagination > li.current > span {
  background: #3a9ac8;
  color: #fff;
  border-color: #3a9ac8;
  text-decoration: none;
}
.pagination > li i {
  font-size: 24px;
  line-height: 40px;
}

.pagination.pagination-custom > li > a,
.pagination.pagination-custom > li > span {
  border-radius: 0;
  border-left-width: 0;
}
.pagination.pagination-custom > li {
  margin: 0;
}
.pagination.pagination-custom > li:first-child > a,
.pagination.pagination-custom > li:first-child > span {
  border-left-width: 1px;
}

/* Checkbox and Radiobox
==========================================================*/
.form-group .checkbox,
.form-group .radio {
  margin: 0;
}
.checkbox label,
.radio label {
  display: inline-block;
  position: relative;
  padding-left: 33px;
  line-height: 1.6;
  margin-right: 20px;
}
.checkbox label:before,
.radio label:before {
  content: "";
  position: absolute;
  width: 23px;
  height: 23px;
  left: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.radio label:before {
  border-radius: 50%;
}
.checkbox label:after,
.radio label:after {
  font-family: "Font Awesome 6 Pro" !important;
  content: "\f00c";
  position: absolute;
  left: 4px;
  top: -1px;
  font-size: 15px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  color: #fff;
}
.radio label:after {
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #3a9ac8;
  left: 6px;
  top: 6px;
  position: absolute;
}
.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  opacity: 0;
  margin: 0;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  top: 0;
}
.checkbox input[type="checkbox"]:checked + label:after,
.radio input[type="radio"]:checked + label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.checkbox input[type="checkbox"]:checked + label:before {
  background: #3a9ac8;
  border-color: #3a9ac8;
}
.radio input[type="radio"]:checked + label:before {
  border-color: #3a9ac8;
}
.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before,
.radio input[type="radio"]:disabled + label::before,
.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label {
  cursor: not-allowed;
}

/* Tables
==========================================================*/
.table-bordered > thead > tr > th {
  border-bottom-color: #3a9ac8;
  background: #f6f6f6;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 15px 20px;
}
.table > thead > tr > th {
  border-bottom-color: #3a9ac8;
}
table th {
  font-weight: 600;
}
.bs-example {
  overflow-x: auto;
}

/* Tooltips
==========================================================*/
.tooltip {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #3a9ac8;
  border-radius: 4px;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #3a9ac8;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #3a9ac8;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #3a9ac8;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #3a9ac8;
}

/* Dropcaps
==========================================================*/
p.drop-caps:first-child:first-letter {
  float: left;
  font-size: 80px;
  line-height: 60px;
  padding: 5px;
  margin-right: 5px;
  margin-top: 5px;
}
p.drop-caps.secondary:first-child:first-letter {
  background: #3a9ac8;
  color: #fff;
  padding: 7px;
  margin-right: 11px;
  border-radius: 5px;
}

/* Form Style
==========================================================*/
button,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
textarea,
select,
input[type="submit"],
input[type="button"] {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  /*vertical-align: middle;*/
}
button,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
textarea,
input[type="submit"],
input[type="button"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}
button,
input {
  line-height: normal;
}
.form-control,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
textarea,
select,
textarea.form-control {
  background: #f6f6f6;
  padding: 7px 20px 5px;
  height: 50px;
  line-height: 1.55;
  border: solid 1px #dbdbdb;
  color: #454648;
  width: 100%;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  -moz-transition: border-color 200ms ease-in;
  -webkit-transition: border-color 200ms ease-in;
  transition: border-color 200ms ease-in;
  border-radius: 5px;
  text-overflow: ellipsis;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
}
textarea,
textarea.form-control {
  width: 100%;
  height: 140px;
  overflow: auto;
  resize: vertical;
  padding: 10px 20px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  border-radius: 12px;
  border-radius: 5px;
}
select {
  height: 50px;
  padding: 10px 42px 9px 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  position: relative;
  z-index: 1;
  background: none;
  line-height: 1.25;
  border-radius: 50px;
  cursor: pointer;
  color: #454648;
  background: #fff;
  border: solid 1px #dbdbdb;
}
select::-ms-expand {
  display: none;
}
.custom-selectbox {
  overflow: hidden;
  display: block;
  margin: 0;
  position: relative;
}
.custom-selectbox:before {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  background: #ebefef;
  content: "";
  height: 48px;
}
.custom-selectbox:after {
  border-right: 2px solid #737373;
  border-top: 2px solid #737373;
  content: "";
  height: 10px;
  margin-top: -8px;
  position: absolute;
  right: 18px;
  top: 50%;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  width: 10px;
  z-index: 1;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
input[type="url"]:focus,
textarea:focus,
select:focus,
.form-control:focus {
  border-color: #c3c3c3;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="button"],
input[type="submit"],
button {
  background: #3a9ac8;
  color: #fff;
  border: 0;
  letter-spacing: 0.01em;
  cursor: pointer;
  width: auto;
  overflow: visible;
  padding: 13px 30px 10px;
  vertical-align: middle;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.4;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 600;
  border-radius: 5px;
}
input[type="button"]:hover,
input[type="submit"]:hover,
button:hover {
  color: #fff;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #737373;
}
::-moz-placeholder {
  opacity: 1;
  color: #737373;
}
:-ms-input-placeholder {
  opacity: 1;
  color: #737373;
}
:-moz-placeholder {
  opacity: 1;
  color: #737373;
}

.form-control {
  height: 50px;
}
.control-label,
.form-group > label {
  font-weight: 600;
}

/* Breadcrumb
==========================================================*/
.breadcrumb {
  font-size: 15px;
  background: none;
  margin: 0;
  padding: 0;
  display: block;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb li.active {
  color: #fff;
}
.breadcrumb li + li:before {
  font-family: "Font Awesome 6 Pro" !important;
  content: "\f105";
  font-size: 16px;
  color: #fff;
  margin-right: 2px;
  padding: 0 5px;
}
.breadcrumb li a {
  color: #fff;
}
.breadcrumb li a:hover {
  color: #3a9ac8;
  text-decoration: none;
}

/* Common button CSS
==========================================================*/
.btn {
  background-color: #3a9ac8;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  display: inline-block;
  padding: 0 25px;
  vertical-align: bottom;
  font-weight: 600;
  position: relative;
  height: 48px;
  line-height: 48px;
  overflow: hidden;
  z-index: 1;
  border: 0;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  border-radius: 5px;
}
.btn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.25);
  height: 0;
  z-index: -1;
}
.btn:hover,
.btn:focus {
  text-decoration: none;
  color: #fff;
  box-shadow: none;
}

.btn.btn-block {
  display: block;
}
.btn.btn-danger {
  background: #d43f3a;
}
.btn.btn-warning {
  background: #f0ad4e;
}
.btn.btn-info {
  background: #5bc0de;
}
.btn.btn-success {
  background: #5cb85c;
}
.btn.btn-primary {
  background: #3a9ac8;
}
.btn.btn-grey {
  background: #888;
}
.btn:active,
.btn.active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn:hover:before,
.btn:focus:before {
  height: 100%;
}
.btn + .btn {
  margin-left: 10px;
}
.btn.btn-white {
  background-color: #fff;
  color: #242424;
}
.btn.btn-white:hover,
.btn.btn-white:focus {
  color: #fff;
}
.btn.btn-white:before {
  background: #3a9ac8;
}
.btn.btn-lg,
input[type="submit"].btn-lg,
input[type="button"].btn-lg,
button.btn-lg {
  padding: 0 35px;
  font-size: 20px;
  height: 56px;
  line-height: 56px;
}
.btn.btn-sm,
input[type="submit"].btn-sm,
input[type="button"].btn-sm,
button.btn-sm {
  padding: 0 20px;
  font-size: 15px;
  height: 42px;
  line-height: 42px;
}
.btn.btn-sm.btn-sm:after {
  margin-bottom: 0;
}
.btn.btn-xs,
input[type="submit"].btn-xs,
input[type="button"].btn-xs,
button.btn-xs {
  padding: 0 15px;
  font-size: 12px;
  height: 35px;
  line-height: 35px;
}
.btn.btn-outline,
input[type="submit"].btn-outline,
input[type="button"].btn-outline,
button.btn-outline {
  background: none;
  border: solid 2px #3a9ac8;
  color: #3a9ac8;
  line-height: 38px;
}
.btn.btn-outline-white,
input[type="submit"].btn-outline-white,
input[type="button"].btn-outline-white,
button.btn-outline-white {
  border-color: #fff;
  color: #fff;
}
.btn.btn-outline:hover,
input[type="submit"].btn-outline:hover,
input[type="button"].btn-outline:hover,
button.btn-outline:hover,
.btn.btn-outline:focus,
input[type="submit"].btn-outline:focus,
input[type="button"].btn-outline:focus,
button.btn-outline:focus {
  color: #fff;
  border-color: #3a9ac8;
}
.btn.btn-outline:before {
  background: #3a9ac8;
}
.btn.btn-outline-white:hover,
input[type="submit"].btn-outline-white:hover,
input[type="button"].btn-outline-white:hover {
  border-color: #fff;
  color: #3a9ac8;
}
.btn.btn-outline:hover:after,
input[type="button"].btn-outline:hover:after,
button.btn-outline:hover:after {
  border-color: #fff;
}
.btn.btn-outline-white:hover:before {
  background: #fff;
}
.btn.btn-lg.btn-outline,
input[type="submit"].btn-lg.btn-outline,
input[type="button"].btn-lg.btn-outline {
  padding: 0 35px;
}
.btn.btn-sm.btn-outline,
input[type="submit"].btn-sm.btn-outline,
input[type="button"].btn-sm.btn-outline {
  padding: 0 20px;
}
.btn.btn-xs.btn-outline,
input[type="submit"].btn-xs.btn-outline,
input[type="button"].btn-xs.btn-outline {
  padding: 0 15px;
  line-height: 28px;
}

/* Demo Page CSS
==========================================================*/
.demo-col {
  text-align: center;
}
.demo-img {
  width: 400px;
  margin: 0 auto 10px;
  display: block;
  overflow: hidden;
  background: url(/images/demo/demo-bg.png) no-repeat 0 0;
  padding: 14px 62px 40px;
  -webkit-background-size: 400px auto;
  background-size: 400px auto;
}
.demo-img span {
  width: 278px;
  left: -1px;
  height: 173px;
  display: block;
  position: relative;
  overflow: hidden;
  background: #000;
}
.demo-img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.demos-container {
  padding: 80px 20px;
  text-align: center;
}
.demos-container h2 {
  font-size: 40px;
  margin: 0 0 60px;
  color: #3a9ac8;
  font-weight: 600;
}
.demos-container h2 ~ h2 {
  margin-top: 40px;
}
.demos-container .row {
  font-size: 0;
}
.demos-container .column {
  float: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 50px;
}

/*========================================================
NEW CSS
==========================================================*/
.demo-img {
  position: relative;
  overflow: visible;
}
.new-page {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  right: 20px;
  top: -30px;
  line-height: 80px;
  text-align: center;
  background: #3a9ac8;
  color: #fff;
  font-weight: 600;
}
.navigation-menu > li > ul li a,
.sidenav-wrapper .nav ul li a {
  position: relative;
}
.navigation-menu > li > ul li a .label,
.sidenav-wrapper .nav ul li a .label {
  float: right;
  margin-left: 5px;
  line-height: 1.2;
  padding: 3px 8px 2px;
  font-size: 70%;
  font-weight: 400;
  position: relative;
  top: 2px;
}
.about-inforow .owl-dots {
  display: none;
}
.purchase-btn {
  position: fixed;
  right: -113px;
  background: #fff;
  color: #242424;
  text-align: center;
  padding: 7px 13px 4px;
  top: 150px;
  z-index: 999;
  font-size: 14px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.purchase-btn:hover,
.purchase-btn:focus {
  text-decoration: none;
  background: #3a9ac8;
  color: #fff;
  right: 0;
}

/*==============================================
28. PORTFOLIO PAGES CSS
================================================*/
.masonry {
  margin: 0 !important;
  padding-bottom: 50px;
}
.masonry .project-img img {
  width: 100%;
}
.grid-item {
  margin: -5px 0 0 !important;
  padding: 0;
}
.btn-row {
  padding-top: 15px;
}
.btn-row a {
  display: inline-block;
  font-size: 24px;
  margin: 0 5px;
}
.project-details-trigger {
  width: 62px;
  height: 62px;
  background: #fff;
  border-radius: 50%;
  line-height: 62px;
}
.project-detail-col p {
  margin-bottom: 18px;
}
.project-detail-col strong {
  color: #242424;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
}
.project-detail-col h3 {
  font-weight: 700;
}
.project-detail-col .page-header {
  margin-top: 10px;
}
.category-tag {
  background: #f4f4f4;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 14px;
  display: inline-block;
  color: #737373;
  margin: 5px 5px 5px 0;
}
.category-tag:hover,
.category-tag:focus {
  text-decoration: none;
  background: #3a9ac8;
  border-color: #3a9ac8;
  color: #fff;
}
.project-share {
  margin: 0;
  padding: 0 0 0 5px;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
}
.view-project {
  margin-top: 20px;
}
.project-share li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px;
}
.project-share li a {
  color: #737373;
  font-size: 18px;
}
.project-share li a:hover,
.related-projectbox h4 a:hover {
  color: #3a9ac8;
  text-decoration: none;
}
.project-detail-col strong.share-project {
  display: inline-block;
  vertical-align: middle;
}
.related-projects h3 {
  font-weight: 700;
}
.related-projects {
  padding-top: 30px;
}
.related-projectbox figure {
  margin-bottom: 20px;
}
.related-projectbox h4 a {
  color: #242424;
}
.related-projectbox h4 {
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.error-content-dark.error-content {
  position: static;
  color: #737373;
  padding: 50px 0;
}
.error-content-dark.error-content h3 {
  color: #242424;
}
.error-content-dark.error-content h1 {
  color: #f0ad4e;
}

/*==============================================
29. COMING SOON
================================================*/
.coming-soon {
  overflow: hidden;
  height: 100vh;
}
.coming-soon-img {
  height: 100vh;
  background-position: 50% 50%;
}
.coming-soon .row {
  margin: 0;
}
.coming-soon .column {
  padding: 0;
}
.coming-soon-wrapper {
  height: 100vh;
  width: 100%;
  text-align: center;
  overflow-y: auto;
}
.coming-soon-box {
  padding: 40px;
}
.company-logo {
  margin-bottom: 50px;
}
.coming-soon-box h1 {
  font-size: 40px;
  margin-bottom: 5px;
}
.countdown {
  margin: 60px -35px 50px;
  padding: 0;
  list-style: none;
}
.countdown li {
  display: inline-block;
  padding: 0 35px;
  font-size: 20px;
}
.countdown li span {
  font-size: 54px;
  font-weight: 700;
  display: block;
  line-height: 1;
  color: #3a9ac8;
}
.get-updates {
  background: #f4f4f4;
  padding: 40px;
  border-radius: 5px;
  margin: 0 20px;
}
.get-updates > span {
  margin-bottom: 15px;
  display: block;
}
.email-subscribe {
  position: relative;
  max-width: 450px;
  margin: 0 auto;
}
.email-subscribe .form-control {
  float: left;
  width: 280px;
  background: #fff;
}
.email-subscribe button {
  float: right;
}

/*==============================================
30. MAINTENANCE
================================================*/
.maintenance-wrapper {
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  overflow: hidden;
}
.maintenance-section {
  height: 100vh;
  position: relative;
  overflow-y: auto;
}
.maintenance-section:before,
.coming-soon-wrapper:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.maintenance-box,
.coming-soon-box {
  display: inline-block;
  vertical-align: middle;
  width: 98%;
}
.maintenance-wrapper:after {
  opacity: 0.8;
  background: #000;
}
.maintenance-box {
  padding: 50px;
  font-size: 20px;
}
.maintenance-box p strong {
  font-weight: 600;
}
.maintenance-box h1 {
  margin: 50px 0 30px;
}
.site-logo img {
  margin-bottom: 100px;
  width: 137px;
}
.maintenance-icon {
  display: block;
  opacity: 0.7;
}
.connect-links {
  margin: 0;
  padding: 0;
  list-style: none;
}
.connect-links li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 18px;
}
.connect-links li a {
  color: #fff;
  font-size: 26px;
}
.stay-connected {
  padding-top: 80px;
}
.connect-links .fb a:hover {
  color: #3b5998;
}
.connect-links .tw a:hover {
  color: #26a6d1;
}
.connect-links .yt a:hover {
  color: #c4302b;
}
.connect-links .in a:hover {
  color: #0e76a8;
}
.connect-links .ig a:hover {
  color: #fb3958;
}

#form-messages:not(:empty) {
  border-radius: 5px;
  padding: 10px 15px;
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
}


.slide-bg {
  height: 100%;
}

.no-webp .slide-main-1 {
  background-image: url(/images/real-life/business_slow_banner.jpg);
}
.webp .slide-main-1 {
  background-image: url(/images/real-life/business_slow_banner.jpg);
}

.no-webp .slide-main-2 {
  background-image: url(/images/real-life/central_console_banner.jpg);
}
.webp .slide-main-2 {
  background-image: url(/images/real-life/central_console_banner.jpg);
}

.no-webp .slide-main-3 {
  background-image: url(/images/real-life/personalized_mobile_banner.jpg);
}
.webp .slide-main-3 {
  background-image: url(/images/real-life/personalized_mobile_banner.jpg);
}

.no-webp .slide-main-4 {
  background-image: url(/images/real-life/customer_loyalty_banner.jpg);
}
.webp .slide-main-4 {
  background-image: url(/images/real-life/customer_loyalty_banner.jpg);
}

.container-fluid {
  padding-right: 0px !important;
}




.as-featured-on{
  height: 165px !important;
  width: 165px  !important;
  margin-left:10px;
  margin-right:10px;
}




/* BILLING & PAYMENTS STYLE */

.invoice-logo {
  background: url("/img/logo-full.svg") top center no-repeat;
  width: 250px;
  height: 100px;
  float: right;
  text-align: right;
  margin-bottom: 10px;
}

.invoice-summary {
  width: 100%;
  border: 1px solid #c3c3c3;
  float: right;
  padding: 4px;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  color: #fff !important;
  opacity: 1 !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.list-group-item {
  border: 1px solid #777777 !important;
  /* border-color: #E5E5E5; */
}

.list-group-item .fa {
  min-width: 20px !important;
  text-align: center !important;
  margin-left: -5px !important;
  margin-right: 5px !important;
}

.info-tooltip-sm {
  color: #3fbae4 !important;
  font-size: 15px !important;
  cursor: pointer !important;
}

.d1 {
  display: table;
}

.d2,
.d3 {
  display: table-cell;
}

.d2 {
  white-space: nowrap;
  border-right: 1px dashed #666;
  padding-right: 10px;
}

.d3 {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.snip1404 {
  font-family: ' Source Sans Pro', Arial, sans-serif;
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  width: 100%;
  margin: 20px 00px;
}

.snip1404 img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: -1;
}

.snip1404 .plan {
  margin: 0;
  width: 25%;
  position: relative;
  float: left;
  overflow: hidden;
  border: 3px solid #192027;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  /* background-color: #5f3047; */
  background-color: #144c67;
}



.snip1404 .plan:first-of-type {
  border-radius: 8px 0 0 8px;
}

.snip1404 .plan:last-of-type {
  border-radius: 0 8px 8px 0;
}

.plan {
  /* border-radius: 8px !important; */
}


.snip1404 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.snip1404 header {
  /* background-color: #5f3047; */
  background-color: #144c67;
  color: #ffffff !important;
}

.snip1404 header h4 {
  /* background-color: #5f3047; */
  background-color: #144c67;
  color: #ffffff !important;
}

.snip1404 .plan-title {
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  margin: 0;
  padding: 20px 20px 0;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.snip1404 .featured .plan-title {
  text-decoration: underline;
}

.snip1404 .plan-title:after {
  position: absolute;
  content: '';
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 300px 0 0;
  border-color: rgba(0, 0, 0, 0.5) transparent transparent;
}

.snip1404 .plan-cost {
  padding: 40px 20px 10px;
  text-align: right;
}

.snip1404 .plan-price {
  font-weight: 600;
  font-size: 3em;
}

.snip1404 .plan-type {
  opacity: 0.8;
  font-size: 0.7em;
  text-transform: uppercase;
}

.snip1404 .plan-features {
  font-family: "Poppins";
  padding: 0 0 20px;
  margin: 0;
  list-style: outside none none;
}

.snip1404 .plan-features li {
  padding: 8px 5%;
}

.snip1404 .plan-features i {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.5);
}

.snip1404 .plan-select {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
}

.snip1404 .plan-select a {
  background-color: #fff;
  color: #144c67;
  text-decoration: none;
  padding: 12px 20px;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: inline-block;
}

.snip1404 .plan-select .exceeded {
  background-color: rgb(113, 0, 0);
  color: #ffffff;
  text-decoration: none;
  padding: 12px 20px;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: inline-block;
}

.snip1404 .plan-select .exceeded:hover {
  background-color: rgb(113, 0, 0);
  color: #fff;
  font-size: 0.75em;
  border: 1px solid #fff;
}

.snip1404 .plan-select .current {
  background-color: rgb(74, 105, 153);
  color: #ffffff;
  text-decoration: none;
  padding: 12px 20px;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: inline-block;
}

.snip1404 .plan-select .current:hover {
  background-color: rgb(74, 105, 153);
  color: #fff;
  font-size: 0.75em;
  border: 1px solid #fff;
}

.snip1404 .plan-select a:hover {
  background-color: #0033ffe6;
  color: #fff;
  font-size: 0.85em;
  border: 1px solid white;
}

.snip1404 .featured {
  box-shadow: 5px 10px #888888;
  margin-top: -10px;
  border-color: #000;
  box-shadow: 0 0 25px #0033ffe6;
  /*box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);*/
  z-index: 1;
  border-radius: 8px !important;
}

.snip1404 .featured .plan-select {
  padding: 30px 20px;
}


@media only screen and (max-width: 1600px) {
  .feature-table2 .price span{
    font-size:38px !important;
  }
  .feature-table2 .price {
    font-size: 38px!important;
    font-weight: 100!important;
  }
  .snip1404 .plan-features {
      font-size: 17px;
  }

  .snip1404 .plan-price {
      font-weight: 600;
      font-size: 2em;
  }

  .snip1404 .plan-title {
      letter-spacing: 2px;
  }
}

@media only screen and (max-width: 1400px) {
  #diagramNetwork{
    display:none !important;
  }
  .features-list-content h3 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px;
  }
    .features-list-content {
      padding-left: 10px;
    }
  
    .features-list-contentbox {
      padding: 0px;
      color: #fff;
    }
  
    .features-list-content h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 5px;
    }

  .info-tooltip-sm {
      font-size: 13px !important;
  }

  .snip1404 .plan-features {
      font-size: 11px;
  }

  .snip1404 .plan-price {
      font-weight: 600;
      font-size: 2em;
  }

  .snip1404 .plan-title {
      letter-spacing: 2px;
  }
}

@media only screen and (max-width: 1200px) {
  .features-list-col>i {
      font-size: 20px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: rgba(36, 36, 36, 0.28);
      text-align: center;
      line-height: 33px;
      display: inline-block;
      vertical-align: middle;
    }
    

    .features-list-content {
      padding-left: 10px;
    }
    
    .features-list-contentbox {
      padding: 0px;
      color: #fff;
    }

    .features-list-content h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 5px;
    }


  
  .feature-table2 .price span {
    font-size: 30px !important;
  }
  .feature-table2 .price {
    font-size: 30px!important;
    font-weight: 100 !important;
  }

  .snip1404 .plan-features {
      font-size: 10px;
  }

  .snip1404 .plan-title {
      letter-spacing: 1px;
  }

  .snip1404 .plan-price {
      font-weight: 600;
      font-size: 2em;
  }

  .snip1404 .plan-title,
  .snip1404 .plan-select a {
      -webkit-transform: translateY(0);
      transform: translateY(0);
  }

  .snip1404 .plan-select,
  .snip1404 .featured .plan-select {
      padding: 20px;
  }

  .snip1404 .featured {
      margin-top: 0;
  }
}
@media only screen and (max-width: 440px) {
  .snip1404 .plan {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .climb-value-ladder {
      background: url('/blank.gif') no-repeat !important;
    }
  .feature-table2 .price span {
      font-size: 25px !important;
    }
  .feature-table2 .price {
    font-size: 25px!important;
    font-weight: 100!important;
  }
}


.separator-content{
  padding: 1px 10px;
  top: -15px;
  position: relative;
  border: 1px solid #888;
  border-radius: 3px;
  background: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.free-trial{
  background-color:#fff !important;
  padding-top:2px !important;
}


.submenu-icon{
  float:right;
  width:30px;
  text-align:center;
  font-size:18px;
}


.footer-link{
  display:inline-block !important;
  width:25px !important;
  text-align:center;
}

/* Dialog Exit Button */
.no-close .ui-dialog-titlebar-close {
  display: none;
}










.feature-table2 .feature-block {
  /*flex: 1;*/
  background-color: #476481;
  color: #fff;
  padding: 20px 10px 0px 10px;
  text-align: center;
  vertical-align: middle !important;
}

.feature-table2 .feature-block .fa{
  /*flex: 1;*/
  font-size:25px;
}

@media(min-width:768px) {
  .feature-table2 {
    display: flex;
  }

  .feature-table2 .feature-block {
    flex: 1;
  }
}

.feature-table2 h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.feature-table2 .th1 {
  background-color: #476481;
}

.feature-table2 .th2 {
  background-color: #3e5871;
}

.feature-table2 .th3 {
  background-color: #354b60;
}

.feature-table2 .th4 {
  background-color: #2c3e50;
}

.feature-table-icon {
  background-color: #6b839a;
  border-radius: 50%;
  border: 1px solid #dbdbdb;
  width: 115px;
  height: 115px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.feature-table-icon-1 {
  background-color: #6B839A;
  /*border-color: #3e5871;*/
}

.feature-table-icon-2 {
  background-color: #476481;
  /*border-color: #354b60;*/
}

.feature-table-icon-3 {
  background-color: #3E5871;
  /*border-color: #2c3e50;*/
}

.feature-table-icon-4 {
  background-color: #354B60;
  /*border-color: #233140;*/
}

.feature-table-icon svg {
  width: 100%;
}

.feature-table2 .price span {
  font-size: 40px;
  font-weight: 100;
}
.feature-table2 .price {
  font-size: 32px;
  font-weight: 100;
}

.feature-table2 .price-int {
  font-size: 18px;
  font-weight: 100;
}


.no-top-margin{
  margin-top: 0px !important;
}

.bordered-logo-container{
  border: 1px solid #cbcbcb;
  border-radius: 20px;
}


.platinum-text {
  background: -webkit-linear-gradient(#e7e7e7, #707070, #000);
  -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #222;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gold-text {
  background: -webkit-linear-gradient(#f3dc1b, #876e0f, #ffc700);
  -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #222;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.silver-text {
  background: -webkit-linear-gradient(#e7e7e7, #adabab, #8d8c8c);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #222;
  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bronze-text {
  background: -webkit-linear-gradient(#9b7d0d, #682d09, #a99113);
  -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #222;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.bronze-background{
  background: linear-gradient(to right, #644b19, #88823e, #745410, #706b30, #916110);
}

.silver-background{
  background: linear-gradient(to right, #c7c7c7, silver, #9f9f9f, #cacaca, #858585);
}

.gold-background{
  background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
}

.platinum-background{
  background: linear-gradient(to right, #464646, #646262, #858585, #ffffff, #f3f3f3);
}


.app-coming-soon{
  opacity: 0.3;
}

.release-date{
  text-align:center;
  padding:10px;
  font-weight:600;
  text-decoration: underline;
}

.tiered-certification-empty{
  padding: 30px;
}
.button-disabled {
  background: gray !important;
  cursor: default !important;
  border: none !important;
}
.field-error {
  border: 1px solid red !important;
}

sup{
  font-size:12px !important;
  color: #000 !important;
  background: #000 !important;
  top: -30px !important;
  margin-right:-90px !important;

  background: none !important;
    -webkit-text-stroke-width: 0px;
    -webkit-text-stroke-color: #222;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000;
  
}

.full-container {
  max-width: 100%;
}

.header-no-margin{
  margin: 0px !important;
}

.feature-table2 h2{
  font-size:30px;
}

.plan-supertext{
  margin-top: -15px;
  font-size: 10px;
  color: #fff !important;
  -webkit-text-stroke-color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  position: relative !important;
}
.plan-strikethrough{
  text-decoration: line-through;
  text-decoration-color:#8b0f0f;
}


.highlight {
  color: #efff00 !important;
}
.show .highlight {
  color: royalblue !important;
}


.rounded-tl {
  border-radius: 0px 20px 10px 10px;
}

.rounded-tr {
  border-radius: 20px 0px 10px 10px;
}
.rounded-brl {
  border-radius: 0px 0px 10px 10px !important;
}

.navigation-menu ul{
  border-right: 2px solid #3a9ac8;
  border-bottom: 2px solid #3a9ac8;
  border-left: 2px solid #3a9ac8;
}
ul a {
  -webkit-transition: all 0ms !important;
  transition: all 0ms !important;

  -moz-transition: all 0ms cubic-bezier(0.45, 1, 0.32, 1), all 0ms cubic-bezier(0.45, 1, 0.32, 1);
  -o-transition: all 0ms ease-in, all 0ms ease-in;
  -ms-transition: all 0ms cubic-bezier(0.45, 1, 0.32, 1), all 0ms cubic-bezier(0.45, 1, 0.32, 1);
}
.navigation-menu ul a:hover {
  -webkit-transition: all 0ms !important;
  transition: all 0ms !important;

  -moz-transition: all 0ms cubic-bezier(0.45, 1, 0.32, 1), all 0ms cubic-bezier(0.45, 1, 0.32, 1);
  -o-transition: all 0ms ease-in, all 0ms ease-in;
  -ms-transition: all 0ms cubic-bezier(0.45, 1, 0.32, 1), all 0ms cubic-bezier(0.45, 1, 0.32, 1);

  background-color: rgb(23, 78, 150);
  color: #fff !important;
  border-radius: none;
}


.rounded-tl> li:first-child  a:hover {
  -webkit-transition: all 0ms !important;
  transition: all 0ms !important;
  background-color: rgb(23, 78, 150);
  color: #fff !important;
  border-radius: 0px 20px 0px 0px;
}

.rounded-tl> li:last-child a:hover {
  -webkit-transition: all 0ms !important;
  transition: all 0ms !important;
  background-color: rgb(23, 78, 150);
  color: #fff !important;
  border-radius: 0px 0px 8px 8px;
}

.rounded-tr>li:first-child a:hover {
  -webkit-transition: all 0ms !important;
  transition: all 0ms !important;
  background-color: rgb(23, 78, 150);
  color: #fff !important;
  border-radius: 20px 0px 0px 0px;
}

.rounded-tr>li:last-child a:hover {
  -webkit-transition: all 0ms !important;
  transition: all 0ms !important;
  background-color: rgb(23, 78, 150);
  color: #fff !important;
  border-radius: 0px 0px 8px 8px;
}


.container-large{
  text-align: center;
  max-width: 1600px;
  width: auto;
  padding: 20px;
}

/* downloads */
.downloads-tools .img-fluid {
  margin-bottom: 20px;
}
.downloads-tools {
  margin-bottom: 40px;
}
.downloads-tools .app-type,
.downloads-tools .app-download-sub {
  color: #81b541;
  font-weight: 500;
}
.downloads-tools .app-download-sub {
  padding-bottom: 10px;
}
.downloads-tools .app-download img {
  margin-bottom: 10px;
}











/* GRAPH */
#mynetwork {
  margin: auto !important;
  width: 1000px;
  height: 600px;
  text-align: center;
}

.vis-tooltip {
  font-size: 20px !important;
}

.cellBatchAttrPopUp {
  display: none;
  position: absolute;
  z-index: 2000;
  padding: 4px 8px;
  color: #333;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #eeeeee), color-stop(1, #cccccc));
  background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
}


.feature-categories img{
  height:100px;
  padding-bottom:10px;
}

.feature-categories h5 {
  font-weight: 900;
}

.video-headers{
  margin-top:20px;
  margin-bottom:0px;
  font-size:26px;
  color: #333;
  font-weight: 900;
}

.video-thumbnail {
  position: relative;
  top: 0;
  left: 0;
}

.video-image {
  position: relative;
  top: 0;
  left: 0;
  border: 3px double #646464;
  opacity: 0.5;
  border-radius:20px;
}

.video-play-button {
  position: absolute;
  height: 81px !important;
  width: 64px !important;
  top: 85px;
  left: 145px;
  border: 0px;
}

.climb-value-ladder{
  background: url('/images/home-icons/climbing-the-value-ladder.png') no-repeat;
  background-position: 100% 100%;
  background-size: contain;
  border-bottom: 1px solid #b2eef6;
}

.contact-icons{
  height: 60px;
  margin-bottom:20px;
}
.faded-edges:before,
.faded-edges:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 100px;
  top: 0;
  height: 100%;
  pointer-events: none;
  /*makes the linkes behind clickable.*/
}
.faded-edges:before {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.faded-edges:after {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

