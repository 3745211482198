.register-now {
  color: #000;
  font-size: 40px;
  border: 1px solid white !important;
  border-radius: 10px;
}

.walkthrough {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
  background: linear-gradient(to right bottom, #66abc6, #278de1);
  border-radius: 35px;
  display: none;
  flex-direction: column;
  flex: 0 0 auto;
  font-size: 14px;
  height: 410px !important;
  overflow: hidden;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 900px;
  z-index: 3000;
  margin: 130px auto;
}
.walkthrough.show {
  display: flex;
  opacity: 0;
  transform: translateY(72px);
}
.walkthrough.reveal {
  opacity: 1;
  transform: translateY(0);
}
.walkthrough .walkthrough-body {
  align-items: center;
  display: flex;
  flex: 1;
}
.walkthrough .walkthrough-body .prev-screen,
.walkthrough .walkthrough-body .next-screen {
  align-self: stretch;
  background: none;
  border: 0;
  margin-top: 200px;
  color: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 24px;
  opacity: 1;
  outline: none;
  padding: 16px;
  transform: scale(1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 1000;
}
.walkthrough .walkthrough-body .prev-screen:hover,
.walkthrough .walkthrough-body .prev-screen:active,
.walkthrough .walkthrough-body .next-screen:hover,
.walkthrough .walkthrough-body .next-screen:active {
  color: white;
  transform-origin: center;
  transform: scale(1.25);
}
.walkthrough .walkthrough-body .prev-screen:disabled,
.walkthrough .walkthrough-body .next-screen:disabled {
  opacity: 0;
}
.walkthrough .walkthrough-body .prev-screen {
  order: 1;
}
.walkthrough .walkthrough-body .next-screen {
  order: 3;
}
.walkthrough .walkthrough-body .screens {
  flex: 1;
  align-self: stretch;
  position: relative;
  margin: 0 -16px;
  padding: 0;
  order: 2;
}
.walkthrough .walkthrough-body .screens .screen {
  position: absolute;
  list-style-type: none;
  padding: 40px 0px;
}

.walkthrough .walkthrough-body h3 {
  font-size: 24px;
  line-height: 1.4em;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}
.walkthrough .walkthrough-body p {
  line-height: 1.6em;
  font-size: 22px;
  margin-top: 16px;
  padding-top: 0;
  color: rgba(255, 255, 255, 0.8);
}
.walkthrough .walkthrough-pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.walkthrough .walkthrough-pagination .dot {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 8px;
  margin: 0 4px;
  transform: scale(0.75);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 8px;
}
.walkthrough .walkthrough-pagination .dot.active {
  background: white;
  transform: scale(1);
  transition-delay: 0.4s;
}
.walkthrough .walkthrough-footer {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-around;
  padding: 0;
}
.walkthrough .walkthrough-footer button {
  height: 40px;
  border: 0;
  background: #5da3f2;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  border-radius: 0;
  color: white;
  flex: 1;
  font-size: 12px;
  margin-top: 266px;
  outline: 0;
  padding: 12px;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  width: 100%;
  bottom: 0;
}

.walkthrough .walkthrough-footer button:hover {
  background: #6babf3;
}
.walkthrough .walkthrough-footer button:active {
  background: #5da3f2;
}
.walkthrough .walkthrough-footer button:disabled {
  cursor: pointer;
}
.walkthrough .walkthrough-footer button.finish {
  background: #3e94f5;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  transform: scale(0, 1);
  transform-origin: center;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.walkthrough .walkthrough-footer button.finish:hover {
  background: #4d9cf6;
}
.walkthrough .walkthrough-footer button.finish:active {
  background: #3e94f5;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  transform: scale(0, 1);
  transform-origin: center;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.walkthrough .walkthrough-footer button.finish:hover {
  background: #4d9cf6;
}
.walkthrough .walkthrough-footer button.finish:active {
  background: #3e94f5;
}
.walkthrough .walkthrough-footer button.finish.active {
  transform: scale(1, 1);
  opacity: 1;
}
.walkthrough .screens {
  margin: 0;
}
.walkthrough .screens .screen {
  opacity: 0;
  position: absolute;
  transform: translateX(-72px);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.walkthrough .screens .screen.active {
  opacity: 1;
  transform: translateX(0) scale(1);
  transition-delay: 0.4s;
}
.walkthrough .screens .screen.active ~ .screen {
  opacity: 0;
  transform: translateX(72px);
}

.walkthrough-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.show-walkthrough-modal {
  display: block;
}

.button.skip {
  background-color: rgb(255, 38, 0) !important;
  opacity: 1;
}
.button.skip:hover {
  background-color: rgb(255, 0, 0) !important;
  opacity: 1;
}

@media only screen and (max-width: 840px) {
  .walkthrough {
    display: none !important;
  }

  .walkthrough-modal {
    display: none !important;
  }
}
